import React, { useEffect, useState } from 'react';

import './Landing.css';
import { Navbar } from '../../../components/navbar/Navbar';
import { Footer } from '../../../components/footer/Footer';

export const Landing: React.FC = (): JSX.Element => {

    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpacity(1);
        }, 250);

        return () => clearTimeout(timer);
    }, []);
    
    return(
        <>
            <div style={{ opacity: opacity, transition: 'opacity 250ms' }}>
                <Navbar />
            </div>
            <section className="landing-wrap">
                <div className="container" style={{position: 'relative'}}>
                    <p className="landing-text">
                        Boîte de nuit<br />
                        Maison Rose
                    </p>
                    <p className="landing-text">
                        Adresse Berzelii Park Rue Hamngatan 2<br />
                        Ville Stockholm Code Postal 111 47
                    </p>
                    <p className="landing-text">
                        Lundi Fermé<br />
                        Mardi Fermé<br />
                        &#62;&#62; Mercredi Ouvert 22-03<br />
                        Jeudi Fermé<br />
                        &#62;&#62; Vendredi Ouvert 23–05<br />
                        &#62;&#62; Samedi Ouvert 23–05<br />
                        Diamanche Fermé
                    </p>
                    <img src="/img/logo/rose.png" alt="Rose logo" className="landing-logo" />
                </div>
            </section>
            <div style={{ opacity: opacity, transition: 'opacity 250ms' }}>
                <Footer />
            </div>
        </>
    )
}